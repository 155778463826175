import useSWR from "swr";
import axios from "axios";

export function usePredict(pair, instrumentData) {
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_API_URL}/instrument/${pair}/predict`,
    (url) =>
      axios
        .post(url, { data: instrumentData })
        .then((response) => response.data)
  );

  // if (instrumentDataIsLoading || isLoading) {
  //   return <p>Loading Data</p>;
  // }

  // if (instrumentDataIsError || error) {
  //   return <p>Loading Data Failed</p>;
  // }

  return {
    predict: data,
    isLoading: isLoading,
    isError: error,
  };
}

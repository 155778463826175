import "./App.css";

import GaugeChart from "react-gauge-chart";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "react-bootstrap/Card";
import { calculateScore } from "./util/util";
import { usePredict } from "./api/predict";
import Spinner from "react-bootstrap/esm/Spinner";

const pairWidgetKey = {
  EUR_USD: 1,
  GBP_USD: 2,
  USD_CHF: 29,
  AUD_USD: 11,
  USD_CAD: 5,
  NZD_USD: 28,
  EUR_CAD: 13,
  EUR_CHF: 14,
  GBP_CAD: 24,
  NZD_CAD: 26,
  EUR_NZD: 20,
  EUR_GBP: 17,
  AUD_CHF: 47,
};

export function Instrument({
  trades,
  pair,
  instrumentDataH2,
  instrumentDataH4,
  instrumentDataH8,
  instrumentDataD,
}) {
  const { isLoading, predict, error } = usePredict(
    pair,
    instrumentDataH4 &&
      instrumentDataH2 && {
        H4: instrumentDataH4,
        H2: instrumentDataH2,
        H8: instrumentDataH8,
        D: instrumentDataD,
      }
  );

  if (isLoading) {
    return (
      <div>
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <div>error</div>;
  }

  let holding = trades.map((t) => t.instrument).includes(pair) || false;

  let score =
    (parseFloat(predict["D"][2]) +
      parseFloat(predict["H8"][2]) +
      parseFloat(predict["H4"][2]) +
      parseFloat(predict["H2"][2]) +
      parseFloat(predict["H4Hun"][2])) /
    5;
  // console.log(score);

  if (holding) {
    let trade = trades.find((t) => t.instrument === pair);

    let direction = "Long";

    if (trade.price > trade.takeProfitOrder.price) {
      direction = "Short";
    }

    return (
      // <></>

      <div>
        <h1>
          {pair} {direction}
        </h1>
        <p>
          <b>200: </b> {parseFloat(predict["D"][2]).toFixed(2)}
        </p>
        <p>
          <b>150: </b> {parseFloat(predict["H8"][2]).toFixed(2)}
        </p>
        <p>
          <b> 100: </b> {parseFloat(predict["H4Hun"][2]).toFixed(2)}
        </p>
        <p>
          <b>75: </b> {parseFloat(predict["H4"][2]).toFixed(2)}
        </p>
        <p>
          <b>50: </b> {parseFloat(predict["H2"][2]).toFixed(2)}
        </p>
        <GaugeChart
          formatTextValue={(t) => {
            if (direction === "Long") {
              if (score > 0.5) {
                return "GOOD";
              }

              if (score < 0.5) {
                return "SELL";
              }
            }

            if (direction === "Short") {
              if (score > 0.5) {
                return "SELL";
              }

              if (score < 0.5) {
                return "GOOD";
              }
            }
          }}
          // return score;

          animateDuration={300}
          colors={
            direction === "Long"
              ? ["red", "orange", "green"]
              : ["green", "orange", "red"]
          }
          arcWidth={0.3}
          percent={score}
          textColor={"black"}
        />
        <iframe
          style={{ width: "100%" }}
          src={`https://widgets.myfxbook.com/widgets/outlook.html?type=1&symbols=${pairWidgetKey[pair]}`}
        />
      </div>
    );
  } else {
    return (
      <div>
        <h1>{pair}</h1>
        <p>
          <b>200: </b> {parseFloat(predict["D"][2]).toFixed(2)}
        </p>
        <p>
          <b>150: </b> {parseFloat(predict["H8"][2]).toFixed(2)}
        </p>
        <p>
          <b> 100: </b> {parseFloat(predict["H4Hun"][2]).toFixed(2)}
        </p>
        <p>
          <b>75: </b> {parseFloat(predict["H4"][2]).toFixed(2)}
        </p>
        <p>
          <b>50: </b> {parseFloat(predict["H2"][2]).toFixed(2)}
        </p>

        <GaugeChart
          formatTextValue={(t) => {
            if (score > 0.5) {
              return "LONG";
            }

            if (score < 0.5) {
              return "SHORT";
            }
          }}
          // return score;

          animateDuration={300}
          colors={["green", "orange", "green"]}
          arcWidth={0.3}
          percent={score}
          textColor={"black"}
        />
        <iframe
          style={{ width: "100%" }}
          src={`https://widgets.myfxbook.com/widgets/outlook.html?type=1&symbols=${pairWidgetKey[pair]}`}
        />
      </div>
    );
  }

  return null;
}

// export function Instrument({
//   pair,
//   // instrumentDataM15,
//   // instrumentDataM30,
//   // instrumentDataH1,
//   // instrumentDataH2,
//   instrumentDataH4,
//   instrumentDataH8,
//   instrumentDataD,

//   trades,
// }) {

//   const {
//     isLoading: predictH4IsLoading,
//     predict: predictH4,
//     error: predictH4IsError,
//   } = usePredict(pair, "H4", instrumentDataH4);

//   const {
//     isLoading: predictH8IsLoading,
//     predict: predictH8,
//     error: predictH8IsError,
//   } = usePredict(pair, "H8", instrumentDataH8);

//   const {
//     isLoading: predictDIsLoading,
//     predict: predictD,
//     error: predictDIsError,
//   } = usePredict(pair, "D", instrumentDataD);

//   // const {
//   //   isLoading: predictM1IsLoading,
//   //   predict: predictM1,
//   //   error: predictM1IsError,
//   // } = usePredict(pair, "M1", instrumentDataM1);

//   if (

//     predictH4IsLoading ||
//     predictH8IsLoading ||
//     predictDIsLoading

//     // || predictM1IsLoading
//   ) {
//     return (
//       <div>
//         <Spinner animation="border" />
//       </div>
//     );
//   }

//   if (

//     predictH4IsError ||
//     predictH8IsError ||
//     predictDIsError
//   ) {
//     return <div>Error</div>;
//   }

//   let combinedPredictions = [
//     ...predictH4.predictions,
//     ...predictH8.predictions,
//     ...predictD.predictions,
//   ];

//   let longTermPredictions = [
//     ...predictH4.predictions,
//     ...predictH8.predictions,
//     ...predictD.predictions,
//   ];

//   let longTermPips = [
//     predictH4.pips,
//     ...predictH4.predictions,
//     predictH8.pips,
//     ...predictH8.predictions,
//     predictD.pips,
//     ...predictD.predictions,
//   ];

//   // console.log(predictM15.predictions);
//   // console.log(combinedPredictions);
//   let score = calculateScore(combinedPredictions, 21);
//   let shortScore = calculateScore(longTermPredictions, 12);
//   let longScore = calculateScore(longTermPredictions, 9);

//   // console.log(pair, longScore);
//   let holding = trades.map((t) => t.instrument).includes(pair) || false;
//   if (holding) {
//     let trade = trades.find((t) => t.instrument === pair);

//     let direction = "Long";

//     if (trade.price > trade.takeProfitOrder.price) {
//       score = 1 - score;
//       shortScore = 1 - shortScore;
//       longScore = 1 - longScore;

//       direction = "Short";
//     }

//     return (
//       <Grid xl={2} xs={12} md={4}>
//         <Card>
//           <Card.Header>
//             <h1>
//               {pair} - {direction}
//             </h1>
//           </Card.Header>
//           <div>
// <GaugeChart
//   formatTextValue={(t) => {
//     if (score === 0.5) {
//       return "HOLD";
//     }

//     if (score > 0.5) {
//       return "GOOD";
//     }

//     if (score < 0.5) {
//       return "SELL";
//     }
//   }}
//   // return score;

//   animateDuration={300}
//   colors={["red", "orange", "green"]}
//   arcWidth={0.3}
//   percent={score}
//   textColor={"black"}
// />
//           </div>
//           <div>
//             <Grid container>
//               <Grid md={6} xs={6}>
//                 <GaugeChart
//                   formatTextValue={(t) => {
//                     if (shortScore === 0.5) {
//                       return "HOLD";
//                     }

//                     if (shortScore > 0.5) {
//                       return "GOOD";
//                     }

//                     if (shortScore < 0.5) {
//                       return "SELL";
//                     }
//                   }}
//                   // return score;

//                   animateDuration={300}
//                   colors={["red", "orange", "green"]}
//                   arcWidth={0.3}
//                   percent={shortScore}
//                   textColor={"black"}
//                 />
//                 {/* <h3 style={{ textAlign: "center" }}>
//                   {shortTermPips.map((iD) => `${iD} `)}
//                 </h3> */}
//               </Grid>
//               <Grid md={6} xs={6}>
//                 <GaugeChart
//                   formatTextValue={(t) => {
//                     if (longScore === 0.5) {
//                       return "HOLD";
//                     }

//                     if (longScore > 0.5) {
//                       return "GOOD";
//                     }

//                     if (longScore < 0.5) {
//                       return "SELL";
//                     }
//                   }}
//                   // return score;

//                   animateDuration={300}
//                   colors={["red", "orange", "green"]}
//                   arcWidth={0.3}
//                   percent={longScore}
//                   textColor={"black"}
//                 />
//                 <h3 style={{ textAlign: "center" }}>
//                   {longTermPips.map((iD) => `${iD} `)}
//                 </h3>
//               </Grid>
//             </Grid>
//           </div>
// <iframe
//   // style={{ width: "100%", }}
//   src={`https://widgets.myfxbook.com/widgets/outlook.html?type=1&symbols=${pairWidgetKey[pair]}`}
// />
//         </Card>
//       </Grid>
//     );
//   }

//   // if (direction[0] === "short") {
//   // }

//   return (
//     <Grid xl={2} xs={12} md={4}>
//       <Card>
//         <Card.Header>
//           <h1>{pair}</h1>
//         </Card.Header>
//         <div>
//           <GaugeChart
//             formatTextValue={(t) => {
//               if (score === 0.5) {
//                 return "HOLD";
//               }

//               if (score > 0.5) {
//                 return "BUY";
//               }

//               if (score < 0.5) {
//                 return "SELL";
//               }
//             }}
//             animateDuration={300}
//             colors={["green", "orange", "green"]}
//             arcWidth={0.3}
//             percent={score}
//             textColor={"black"}
//           />
//         </div>
//         <div>
//           <Grid container>
//             <Grid md={6} xs={6}>
//               <GaugeChart
//                 formatTextValue={(t) => {
//                   if (shortScore === 0.5) {
//                     return "HOLD";
//                   }

//                   if (shortScore > 0.5) {
//                     return "BUY";
//                   }

//                   if (shortScore < 0.5) {
//                     return "SELL";
//                   }
//                 }}
//                 // return score;

//                 animateDuration={300}
//                 colors={["green", "orange", "green"]}
//                 arcWidth={0.3}
//                 percent={shortScore}
//                 textColor={"black"}
//               />
//               {/* <h3 style={{ textAlign: "center" }}>
//                 {shortTermPips.map((iD) => `${iD} `)}
//               </h3> */}
//             </Grid>
//             <Grid md={6} xs={6}>
//               <GaugeChart
//                 formatTextValue={(t) => {
//                   if (longScore === 0.5) {
//                     return "HOLD";
//                   }

//                   if (longScore > 0.5) {
//                     return "BUY";
//                   }

//                   if (longScore < 0.5) {
//                     return "SELL";
//                   }
//                 }}
//                 // return score;

//                 animateDuration={300}
//                 colors={["green", "orange", "green"]}
//                 arcWidth={0.3}
//                 percent={longScore}
//                 textColor={"black"}
//               />
//               <h3 style={{ textAlign: "center" }}>
//                 {longTermPips.map((iD) => `${iD} `)}
//               </h3>
//             </Grid>
//           </Grid>
//         </div>
//         <iframe
//           // style={{ width: "100%", height: "1000px" }}
//           src={`https://widgets.myfxbook.com/widgets/outlook.html?type=1&symbols=${pairWidgetKey[pair]}`}
//         />
//       </Card>
//     </Grid>
//   );
// }

// // export default App;
